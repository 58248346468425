import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment"
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import PlaceholderPlugin from "ckeditor5-placeholder"
import {Table, TableCaption, TableToolbar} from "@ckeditor/ckeditor5-table/src"
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting"
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle"
import {Image, ImageUpload, ImageToolbar, ImageStyle, ImageResize} from "@ckeditor/ckeditor5-image/src"
import SimpleUploadAdaptor  from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter"
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline"
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote"
import {Indent, IndentBlock} from "@ckeditor/ckeditor5-indent/src"
import {FontColor, FontSize, FontFamily} from "@ckeditor/ckeditor5-font/src"
import HtmlEmbed from "@ckeditor/ckeditor5-html-embed/src/htmlembed"
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading"
import DOMPurify from "dompurify"
import store from '@/store'

export const classicEditorConfig = {
    plugins: [AlignmentPlugin, EssentialsPlugin, ParagraphPlugin, PlaceholderPlugin, BoldPlugin, ItalicPlugin,
        LinkPlugin, Table, TableCaption, TableToolbar, SourceEditing, ListStyle, Image, ImageUpload, ImageToolbar,
        ImageStyle, ImageResize, SimpleUploadAdaptor, HorizontalLine, BlockQuote, Indent, IndentBlock, FontColor,
        FontSize, FontFamily, HtmlEmbed, HeadingPlugin],
    //removePlugins: ['Heading'],
    toolbar: ['heading', 'bold', 'italic', '|', 'link', 'placeholder', 'alignment', 'undo', 'redo', 'numberedList',
        'bulletedList', 'insertTable', 'sourceEditing', 'imageUpload', 'horizontalLine', 'blockQuote', 'outdent', 'indent',
        'fontSize', 'fontColor', 'fontFamily', 'htmlEmbed'],
    table: {
        contentToolbar: [
            'toggleTableCaption', 'tableColumn', 'tableRow', 'mergeTableCells',
        ]
    },
    image: {
        toolbar: [
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'linkImage'
        ]
    },
    placeholderProps: {
        types: store.state.general_data.placeholders,
    },
    simpleUpload: {
        uploadUrl: process.env.VUE_APP_API_ENDPOINT + 'api/third_party/ckeditor/image_upload',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        }
    },
    htmlEmbed: {
        showPreviews: true,
        sanitizeHtml: (inputHtml) => {
            // Strip unsafe elements and attributes, e.g.:
            // the `<script>` elements and `on*` attributes.
            const outputHtml = DOMPurify.sanitize(inputHtml);

            return {
                html: outputHtml,
                // true or false depending on whether the sanitizer stripped anything.
                hasChanged: DOMPurify.removed.length
            };
        }
    }
}
