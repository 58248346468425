<template>
  <div class="text-right">
    <nk-button type="" is-icon-only-button class="btn-trigger" v-on:click="setModalOpenStatus(true)">
      <nio-icon icon="ni-edit"></nio-icon>
    </nk-button>
  </div>
  <nk-data-list :title="$t('Basic')">
    <n-k-data-list-item label="Company name" :value="userData.company_name"></n-k-data-list-item>
    <n-k-data-list-item label="Email" :value="userData.email"></n-k-data-list-item>
    <n-k-data-list-item label="Phone number" :value="userData.phone_number"></n-k-data-list-item>
    <n-k-data-list-item label="AFM number" :value="userData.afm_number"></n-k-data-list-item>
  </nk-data-list>
  <nk-data-list title="Contact">
    <n-k-data-list-item label="Province" :value="userData.province"></n-k-data-list-item>
    <n-k-data-list-item label="City" :value="userData.city"></n-k-data-list-item>
    <n-k-data-list-item label="Postcode" :value="userData.post_code"></n-k-data-list-item>
    <n-k-data-list-item label="Address" :value="userData.house_no+', '+userData.street"></n-k-data-list-item>
  </nk-data-list>

  <nk-data-list :title="$t('PREFERENCES')">
    <n-k-data-list-item :value="activeLang === 'en' ? $t('English'): 'Dutch'" label="Language">
      <div class="data-col data-col-end">
        <drop-down icon="ni-edit" title="" trigger-btn-classes="data-more" no-body-padding>
          <template v-slot:body>
            <ul class="link-check">
            <li><span>{{ $t('Language') }}</span></li>
            <li :class="{active: activeLang === 'en'}"><a href="javascript:;" v-on:click="changeLang('en')">{{$t('English')}}</a></li>
            <li :class="{active: activeLang === 'nl'}"><a href="javascript:;" v-on:click="changeLang('nl')">Dutch</a></li>
            </ul>
          </template>
        </drop-down>
      </div>
    </n-k-data-list-item>
  </nk-data-list>

  <ion-modal
      :is-open="isModalOpenRef"
      @didDismiss="setModalOpenStatus(false)"
      :css-class="isDesktop ? 'modal-web' : ''"
  >
    <i-modal :title="$t('Update Profile')" @modal-close="setModalOpenStatus(false)">
      <nav-tabs>
        <template v-slot:tabs>
          <nav-tab-item
              v-for="tab in tabs"
              :key="tab.id"
              :tab-id="tab.id"
              :tab-name="$t(tab.name)"
              :current-tab="currentTab"
              @switch-tab="switchTab"></nav-tab-item>
        </template>
        <template v-slot:content>
          <nav-tab-pane tab-id="profile" :class="{active: currentTab === 'personal'}">
            <form-group>
              <row>
                <column md="6" class="mb-3 mb-md-0">
                  <label class="form-label" for="com_name">{{ $t('Company name') }}</label>
                  <b-input type="text" size="lg" id="com_name" v-model="userUpdatedData.company_name"/>
                </column>
              </row>
            </form-group>
            <form-group>
              <row>
                <column md="6" class="mb-3 mb-md-0">
                  <label class="form-label" for="email">{{ $t('Email') }}</label>
                  <b-input type="email" size="lg" id="email" v-model="userUpdatedData.email" readonly=""/>
                </column>
                <column md="6" class="mb-3 mb-md-0">
                  <label class="form-label" for="phone">{{ $t('Phone number') }}</label>
                  <b-input type="text" size="lg" id="phone" v-model="userUpdatedData.phone_number"/>
                </column>
              </row>
            </form-group>
            <form-group>
              <row>
                <column md="6" class="mb-3 mb-md-0">
                  <label class="form-label" for="kvk_number">{{ $t('Chamber of commerce number') }}</label>
                  <b-input
                      type="text"
                      size="lg"
                      id="kvk_number"
                      v-model="userUpdatedData.kvk_number"/>
                </column>
                <column md="6" class="mb-3 mb-md-0">
                  <label class="form-label" for="afm">{{ $t('AFM number') }}</label>
                  <b-input
                      type="text"
                      size="lg"
                      id="afm"
                      v-model="userUpdatedData.afm_number"/>
                </column>
              </row>
            </form-group>
          </nav-tab-pane>
          <nav-tab-pane tab-id="contact" :class="{active: currentTab === 'contact'}">
            <form-group>
              <row>
                <column md="6" class="mb-3 mb-md-0">
                  <row>
                    <column lg="6">
                      <label class="form-label" for="postcode">Postcode</label>
                      <b-input
                          type="text"
                          size="lg"
                          id="postcode"
                          placeholder="5542 AW"
                          v-model="userUpdatedData.post_code"/>
                    </column>
                    <column lg="6">
                      <label class="form-label" for="house_no">{{ $t('House number') }}</label>
                      <b-input
                          type="text"
                          size="lg"
                          id="house_no"
                          v-model="userUpdatedData.house_no"/>
                    </column>
                  </row>
                </column>
              </row>
            </form-group>
          </nav-tab-pane>
        </template>
      </nav-tabs>
      <template v-slot:footer class="p-5">
        <nk-button type="primary" class="mr-2" v-on:click="updateProfile(userUpdatedData)">{{ $t('Update Profile') }}</nk-button>
        <a href="javascript:;" class="link link-light" v-on:click="setModalOpenStatus(false)">{{ $t('Cancel') }}</a>
      </template>
    </i-modal>
  </ion-modal>
</template>

<script>
import {NkDataList, NKDataListItem} from "@core/layouts/data-list"
import {IonModal, isPlatform, loadingController} from "@ionic/vue";
import IModal from "@core/components/ion-modal/IModal";
import {computed, reactive, ref, watch} from "vue";
import NkButton from "@core/components/button/NkButton";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NavTabs from "@core/components/nav-tabs/NavTabs";
import NavTabItem from "@core/components/nav-tabs/NavTabItem";
import NavTabPane from "@core/components/nav-tabs/NavTabPane";
import {FormGroup, Row, Column} from "@core/layouts";
import BInput from "@core/components/bp-form/components/BInput";

import DropDown from "@core/components/dropdown/DropDown";
import i18n from "@/libs/i18n"

import useUpdateProfile from "@/views/settings/useUpdateProfile";
import {useStore} from "vuex";

export default {
  components: {
    DropDown,
    BInput,
    Column,
    Row,
    FormGroup,
    NavTabPane, NavTabItem, NavTabs, NioIcon, NkButton, NkDataList, NKDataListItem, IonModal, IModal},
  setup(){

    const store = useStore()
    const isDesktop = isPlatform('desktop')
    const { updateProfile } = useUpdateProfile(store)
    let userData = computed(()=>{
      return store.state.user_data
    })

    let isModalOpenRef = ref(false)
    watch(()=> store.state.settings.profileUpdatePopup, (n) => {
      isModalOpenRef.value = n
    })

    let userUpdatedData = computed(()=> reactive(Object.assign({}, store.state.user_data)))

    const setModalOpenStatus = (s) => {
      store.dispatch('settings/open_profile_update_popup', s)
    }

    const tabs = [
      {
        name: 'Personal',
        id: 'personal',
      },
      {
        name: 'Address',
        id: 'contact',
      }
    ]
    let currentTab = ref('personal')

    const switchTab = (tab) => {
      currentTab.value = tab
    }

    const changeLang = async (lang) => {
      const l = await loadingController.create({message: i18n.global.t('Processing...')})
      await l.present()
      store.dispatch('appConfig/change_language', lang).then(()=> i18n.global.locale = lang)
          .catch().then(()=>l.dismiss())
    }

    return {
      currentTab,
      userData,
      isDesktop,
      isModalOpenRef,
      setModalOpenStatus,
      switchTab,
      tabs,
      userUpdatedData,
      updateProfile,

      changeLang,
      activeLang: computed(()=> store.state.appConfig.lang),
    }
  },
}
</script>
