<template>
  <ion-header v-if="isPlatform('hybrid')" class="ion-no-border">
    <ion-toolbar class="is-ion-safe-area">
      <ion-title>{{ $t('Account') }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <div class="card-inner-group" data-simplebar>
    <div class="card-inner">
      <user-small-card
          :user-name="userRole === 'is-company' ? currentUserData.company_name : currentUserData.first_name+' '+currentUserData.last_name"
          :user-email="currentUserData.email"
          :image="currentUserData.dp"
          :initials="currentUserData.initials">
        <template v-slot:user_actions>
          <li><a href="javascript:;" v-on:click="isImagePopupOpen=true"><em class="icon ni ni-camera-fill"></em><span>{{ $t('Change')+' '+$t('Photo') }}</span></a></li>
          <li class="d-lg-none"><a href="javascript:;" v-on:click="openProfileUpdateModal"><nio-icon icon="ni-edit-fill"></nio-icon><span>{{ $t('Update Profile') }}</span></a></li>
        </template>
      </user-small-card>
    </div>
    <div class="card-inner p-0">
      <ul class="link-list-menu">
        <template v-for="(tab,tIndex) in tabs" :key="tIndex">
          <li v-if="undefined === tab.display || tab.display" :class="{active: (currentTab === tIndex)}" class="text-capitalize">
            <a href="javascript:;" v-on:click="switchTab(tIndex)">
              <nio-icon :icon="tab.icon"></nio-icon>
              <span>{{ $t(tab.title) }}</span></a>
          </li>
        </template>
      </ul>
    </div>
    <div class="card-inner" v-if="currentUserData.last_login">
      <h6 class="overline-title-alt">{{ $t('Last Login') }}</h6>
      <p>{{ currentUserData.last_login }}</p>
    </div>
  </div>
  <image-upload @on-receive-blob="uploadDP" @on-modal-close="isImagePopupOpen=false" :modal-open="isImagePopupOpen"></image-upload>
</template>

<script>
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import store from "@/store"
import NioIcon from "@core/components/nio-icon/NioIcon";
import useCommon from "@core/comp-functions/common"
import ImageUpload from "@core/components/image-upload/ImageUpload";
import {ref} from "vue";
import {alertController, isPlatform} from "@ionic/vue";
import {useI18n} from "vue-i18n";
import storeHelper from '@/store/helper'

export default {
  name: "SettingsSidebar",
  components: {NioIcon, UserSmallCard, ImageUpload},
  props:{
    userName: String,
    currentTab: {
      default: 'p_info',
    },
    tabs: {
      required: true,
      type: Object,
    },
  },
  emits: ['switchTab'],
  setup( props, {emit} ){

    const{currentUserData, userRole} = storeHelper()
    const{t} = useI18n()
    let isImagePopupOpen = ref(false)
    const { changeDP } = useCommon()

    const switchTab = ( toTab ) => emit('switchTab', toTab)

    const openProfileUpdateModal = () => {
      store.dispatch('settings/open_profile_update_popup', true)
    }
    const uploadDP = (blob) => {
      changeDP(blob)
      .then(r=>{
        if(store.state.auth.user_role === 'is-advisor'){
          store.commit('commit_advisor_data', {dp: r})
        }
        else{
          store.commit('commit_admin_data', {dp:r})
        }
      })
      .catch(r => {
        alertController.create({
          cssClass: isPlatform('desktop') ? 'alert-web' : '',
          header: t('Error')+'!', message: r,
          buttons: [
            {
              text: t('OK'), cssClass: isPlatform('desktop') ? 'alert-btn-light' : '',
            }
          ]
        }).then((a) => a.present())
      })
    }

    return {
      changeDP,
      currentUserData,
      isImagePopupOpen,
      isPlatform,
      switchTab,
      openProfileUpdateModal,
      uploadDP,
      userRole,
    }
  }
}
</script>
