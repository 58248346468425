<template>
  <page-template>
    <main-block>
      <block-content>
        <card no-padding>
          <page-layout :aside-active="asideActive" @toggle-sidebar="asideStatus">
            <template v-slot:contentWrapper>
              <settings-content-header
                  :title="currentTabTitle.title"
                  :description="currentTabTitle.desc"
                  @toggle-sidebar="asideStatus"></settings-content-header>
              <div class="nk-block">
                <content-user-profile-settings v-if="currentTab === 'p_info'"></content-user-profile-settings>
                <content-security-settings v-if="currentTab === 'security'"></content-security-settings>
                <content-activity-log :class="{'d-none' : currentTab !== 'activity'}"></content-activity-log>
                <div v-if="currentTab === 'notifications'">
                  <content-notification-setting></content-notification-setting>
                  <company-notification-settings></company-notification-settings>
                </div>
              </div>
            </template>
            <template v-slot:aside>
              <settings-sidebar
                  :tabs="tabs"
                  :current-tab="currentTab"
                  @switch-tab="switchTab"></settings-sidebar>
            </template>
          </page-layout>
        </card>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {computed, defineComponent, ref, watch} from 'vue';
import {BlockContent, MainBlock} from "@core/layouts";
import {Card} from "@core/components/cards";
import SettingsSidebar from "@/views/settings/components/SettingsSidebar";
import SettingsContentHeader from "@/views/settings/components/ContentHeader";
import ContentUserProfileSettings from "./settings/components/ContentUserProfile";
import ContentSecuritySettings from "@/views/settings/components/ContentSecuritySettings";
import ContentActivityLog from "@/views/settings/components/ContentActivityLog";
import {useRoute} from "vue-router"
import ContentNotificationSetting from "@/views/settings/components/ContentNotificationSetting";
import PageLayout from "@/views/settings/components/PageLayout";
import CompanyNotificationSettings from "@/views/company/settings/components/CompanyNotificationSettings";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    CompanyNotificationSettings,
    PageLayout,
    ContentNotificationSetting,
    ContentActivityLog,
    ContentSecuritySettings,
    ContentUserProfileSettings,
    SettingsContentHeader,
    SettingsSidebar,
    Card,
    BlockContent,
    MainBlock,
  },
  setup() {

    let asideActive = ref(false)
    const asideStatus = () => {
      asideActive.value = !asideActive.value
    }

    let route = useRoute()

    let currentTab = ref( (route.params.tab ? route.params.tab : 'p_info') )
    const switchTab = (toTab) => {
      currentTab.value = toTab
      asideActive.value = false
    }

    watch( () => route.params.tab, (n) => {
      currentTab.value = n ? n : 'p_info'
    })

    const tabs = {
      p_info: {
        title: 'Personal Information',
        icon: 'ni-user-fill-c',
        desc: 'settings.tabs_description.personal',
      },
      notifications: {
        title: 'Notifications',
        icon: 'ni-bell-fill',
        desc: 'settings.tabs_description.notifications',
      },
      activity: {
        title: 'Account Activity',
        icon: 'ni-activity-round-fill',
        desc: 'settings.tabs_description.activity',
      },
      security: {
        title: 'Security Settings',
        icon: 'ni ni-lock-alt-fill',
        desc: 'settings.tabs_description.security',
      },
    }
    const currentTabTitle = computed(()=> tabs[currentTab.value])

    return {
      asideActive,
      asideStatus,
      switchTab,
      tabs,
      currentTab,
      currentTabTitle,
    }
  }
});

</script>
