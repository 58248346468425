<template>
  <div class="data-item">
    <div class="data-col">
      <span class="data-label">{{ label.length > 50 ? label : $t(label) }}</span>
      <span class="data-value">{{ value }}</span>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      required: true,
    },
    value: {
      required: true,
    },
  },
}
</script>