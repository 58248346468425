<template>
  <card>
    <template v-slot:grouped>
      <div class="card-inner">
        <div class="between-center flex-wrap flex-md-nowrap g-3">
          <div class="nk-block-text">
            <h6>{{$t('Save my Activity Logs')}}</h6>
            <p>{{$t('advisor_activity_log_p')}}</p>
          </div>
          <div class="nk-block-actions">
            <ul class="align-center gx-3">
              <li class="order-md-last">
                <div class="custom-control custom-switch mr-n2">
                  <input type="checkbox" class="custom-control-input" checked="" id="activity-log">
                  <label class="custom-control-label" for="activity-log"></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-inner">
        <div class="between-center flex-wrap g-3">
          <div class="nk-block-text">
            <h6>{{ $t('Change Password') }}</h6>
            <p>{{ $t('advisor_set_unique_password_s') }}</p>
          </div>
          <div class="nk-block-actions flex-shrink-sm-0">
            <ul class="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
              <li class="order-md-last">
                <nk-button type="primary" v-on:click="setModalOpenStatus(true)">{{ $t('Change Password') }}</nk-button>
              </li>
              <li v-if="passwordLastChanged.password_updated_at">
                <em class="text-soft text-date fs-12px">{{ $t('Last changed') }}: <span>{{ passwordLastChanged.password_updated_at }}</span></em>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </card>

  <ion-modal
      :is-open="isModalOpenRef"
      @didDismiss="setModalOpenStatus(false)"
      :css-class="isDesktop ? 'modal-web' : ''"
  >
    <i-modal :title="$t('Change your password')" @modal-close="setModalOpenStatus(false)">
      <div style="max-width: 350px;">
        <!-- don't ask for old password if user has signed up with Google etc -->
        <form-group v-if="passwordLastChanged.has_password">
          <label class="overline-title" for="c_password">{{ $t('Current')+' '+$t('Password') }}</label>
          <b-input type="password" v-model="currentPassword" id="c_password"></b-input>
        </form-group>
        <form-group>
          <label class="overline-title" for="new_pwd">{{ $t('New Password') }}</label>
          <b-input type="password" v-model="newPassword" id="new_pwd"></b-input>
        </form-group>
        <form-group>
          <label class="overline-title" for="r_new_pwd">{{ $t('Repeat password') }}</label>
          <b-input type="password" v-model="reNewPassword" id="r_new_pwd"></b-input>
        </form-group>
        <div class="mt-3">
          <ion-spinner v-if="pwdIsUpdating"></ion-spinner>
          <nk-button type="dark" v-else v-on:click="changePassword">{{ $t('Save')+' '+$t('Password') }}</nk-button>
        </div>
      </div>
    </i-modal>
  </ion-modal>

</template>

<script>
import Card from "@core/components/cards/Card";
import NkButton from "@core/components/button/NkButton";
import {IonModal, toastController, alertController, isPlatform} from "@ionic/vue"
import IModal from "@core/components/ion-modal/IModal";
import {ref} from "vue";
import FormGroup from "@core/layouts/form-group/FormGroup";
import BInput from "@core/components/bp-form/components/BInput";
import axios from "@/libs/axios";
import {useStore} from "vuex"
import {useI18n} from "vue-i18n";
import useCommonFunc from "@core/comp-functions/common";
import useStoreHelper from '@/store/helper'

export default {
  name: "ContentSecuritySettings",
  components: {BInput, FormGroup, NkButton, Card, IonModal, IModal},
  setup(){

    const{endPointByRole, userRole} = useStoreHelper()
    const{passwordValidationRegex} = useCommonFunc()
    const{t} = useI18n()
    const isDesktop    = isPlatform('desktop')
    let isModalOpenRef = ref(false)
    const setModalOpenStatus = (status) => isModalOpenRef.value = status

    let pwdIsUpdating = ref(false)

    let currentPassword = ref('')
    let newPassword = ref('')
    let reNewPassword = ref('')

    const store = useStore()
    let passwordLastChanged = ''
    if(store.state.auth.user_role === 'is-advisor'){
      passwordLastChanged = store.state.advisor_data
    }
    else{
      passwordLastChanged = store.state.admin_data
    }

    const changePassword = async () => {
      const alertB = await alertController.create({
        header: t('Error'),
        cssClass: isDesktop ? 'alert-web' : '',
        buttons: [
          {
            text: t('OK'),
            cssClass: isDesktop ? 'alert-btn-light' : '',
          }
        ]
      })
      let error = ''
      if(!currentPassword.value.length && passwordLastChanged.has_password){
        error = `- ${t('errors.provide_current_pwd')}.<br/>`;
      }
      if(!passwordValidationRegex.test(newPassword.value)){
        error += `- ${t('invalid_password')}<br/>`
      }
      if(newPassword.value !== reNewPassword.value){
        error += `- ${t('registration.password_must_match')}.`
      }
      if(error.length){
        alertB.message = error
        await alertB.present()
        return false
      }
      pwdIsUpdating.value = true
      const toast = await toastController.create({color: "success", duration: 3000})
      let tmp = {
        'is-advisor': '/change_password',
        'is-admin': '/account/change_password',
        'is-company': '/account/change_password',
      }
      axios.post(endPointByRole+tmp[userRole.value], {c_password: currentPassword.value, password: newPassword.value})
      .then(resp => {
        if(resp.data.success){
          toast.message = t('thank_you_messages.pwd_updated')
          toast.present()
          newPassword.value = reNewPassword.value = currentPassword.value = ''
          // Set has_password to true
          if(store.state.auth.user_role === 'is-advisor'){
            store.commit('commit_advisor_data', {has_password: true})
          }
          else{
            store.commit('commit_admin_data', {has_password: true})
          }
        }
        else{
          alertB.message = resp.data.message
          alertB.present()
        }
      })
      .catch(error => {
        alertB.message = t('Error')+': '+error.response.status+' '+error.response.statusText
        alertB.present()
      })
      .then( () => pwdIsUpdating.value = false)
    }

    return {
      isDesktop,
      isModalOpenRef,
      setModalOpenStatus,
      pwdIsUpdating,
      currentPassword,
      newPassword,
      reNewPassword,
      changePassword,
      passwordLastChanged,
    }
  },
}
</script>
