<template>
  <div class="nk-block-head nk-block-head-sm">
    <div class="nk-block-head-content">
      <h6>{{$t('Appointment Notification')}}</h6>
      <p class="text-secondary">{{$t('com_appointment_notification_desc')}}</p>
    </div>
  </div>
  <div class="nk-block-content">
    <div class="gy-3">
      <div class="g-item">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="on_ap_assignment_to_adv"
                 v-model="nSettings.to_com_on_ap_assign_to_adv.notify" v-on:change="saveSettings(true)">
          <label class="custom-control-label text-secondary" for="on_ap_assignment_to_adv">{{$t('Notify when your advisors gets assigned an appointment.')}}</label>
        </div>
      </div>
    </div>
    <div class="gy-3" v-if="nSettings.to_com_on_ap_assign_to_adv.notify">
      <div class="g-item">
        <label class="overline-title">{{ $t('Send to') }}:</label>
        <row>
          <column :lg="4">
            <b-input type="email" v-model="nSettings.to_com_on_ap_assign_to_adv.to_email" v-on:blur="saveSettings(true)" :placeholder="currentUserData.email"></b-input>
          </column>
        </row>
      </div>
    </div>
  </div>

  <div class="nk-block-head nk-block-head-sm">
    <div class="nk-block-head-content">
      <h6>{{$t('Notify Lead')}}</h6>
      <p class="text-secondary">{{$t('com_appointment_to_adv_notify_lead')}}</p>
    </div>
  </div>
  <div class="nk-block-content">
    <div class="gy-3">
      <div class="g-item">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="on_ap_assignment_to_lead"
                 v-model="nSettings.to_lead_on_assign_to_adv.notify" v-on:change="saveSettings(true)">
          <label class="custom-control-label text-secondary" for="on_ap_assignment_to_lead">{{$t('Send email to a lead when appointment agent assign it to an advisor.')}}</label>
        </div>
      </div>
    </div>
    <div class="gy-3" v-if="nSettings.to_lead_on_assign_to_adv.notify">
      <div class="g-item">
        <label class="overline-title">{{ $t('Mail content') }}:</label>
        <a href="javascript:;" v-on:click="display.to_lead_mail_content_modal=true">{{ $t('View template') }}</a>
      </div>
    </div>
  </div>

  <ion-modal
      :is-open="display.to_lead_mail_content_modal"
      @didDismiss="display.to_lead_mail_content_modal=false"
      :css-class="isDesktop ? 'modal-web': ''">
    <i-modal :title="$t('Notification template')" @modal-close="display.to_lead_mail_content_modal=false">
      <ckeditor v-model="nSettings.to_lead_on_assign_to_adv.mail_content" :editor="ClassicEditor" :config="classicEditorConfig"></ckeditor>
      <template v-slot:footer>
        <nk-button type="primary" v-on:click="[saveSettings(),display.to_lead_mail_content_modal=false]">{{ $t('Save')}}</nk-button>
        <nk-button type="light" class="ml-2" v-on:click="display.to_lead_mail_content_modal=false">{{ $t('Close')}}</nk-button>
      </template>
    </i-modal>
  </ion-modal>

</template>

<script>

import {onMounted, reactive} from "vue";
import {IonModal, isPlatform, loadingController} from "@ionic/vue";
import BInput from "@core/components/bp-form/components/BInput";
import Column from "@core/layouts/col/Col";
import Row from "@core/layouts/row/Row";
import CKEditor from "@ckeditor/ckeditor5-vue"
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor"
import {classicEditorConfig} from '@/libs/editor-config'
import IModal from "@core/components/ion-modal/IModal";
import axios from "@/libs/axios"
import {useI18n} from "vue-i18n";
import useIonComponents from "@core/IonComponents"
import useCommonFunc from "@core/comp-functions/common"
import storeHelper from "@/store/helper"
import eTemplatesFunctions from "@/views/email-templates/eTemplatesFunctions";
import {useStore} from "vuex";

export default {
  components: {IModal, Row, Column, BInput, ckeditor: CKEditor.component, IonModal,},
  setup(){

    const {t} = useI18n()
    const isDesktop = isPlatform('desktop')
    const {IonSimpleAlert} = useIonComponents()
    const {updateObject} = useCommonFunc()
    const {currentUserData} = storeHelper()
    const store = useStore()
    const {getPlaceholders} = eTemplatesFunctions()

    // Notifications setting
    let nSettings = reactive({
      to_com_on_ap_assign_to_adv: { // When appointment is assigned to a company advisor, notify the company.
        notify: false,
        to_email: '', // email address
      },
      to_lead_on_assign_to_adv:{ // When appointment is assigned to advisor, notify the lead about it.
        notify: false,
        mail_content: '',
      }
    })
    let display = reactive({
      to_lead_mail_content_modal: false,
    })

    const getSettings = async () => {
      const l = await loadingController.create({message: t('Please wait...')})
      await l.present()
      axios.get('/api/company/settings?s_name=notifications_setting')
          .then(resp =>{
            updateObject(nSettings, resp.data.data)
          })
          .catch(er=>{
            let msg = t('errors.general_error');
            if(er.response){
              msg = er.response.data.message || er.response.status_text+' '+er.response.statusText
            }
            IonSimpleAlert('Error!', msg, 'OK')
          })
          .then(()=> l.dismiss())
    }
    const saveSettings = async (silent) => {
      const l = await loadingController.create({message: t('Please wait...')})
      if(!silent){
        await l.present()
      }
      axios.put('/api/company/settings', nSettings)
          .then(()=>{

          })
          .catch(er=>{
            let msg = t('errors.general_error');
            if(er.response){
              msg = er.response.data.message || er.response.status_text+' '+er.response.statusText
            }
            IonSimpleAlert('Error!', msg, 'OK')
          })
          .then(()=> l.dismiss())
    }

    onMounted(()=>{
      getSettings()
      if(!store.state.general_data.placeholders.length){
        getPlaceholders().then((d) => store.commit('commit_placeholders', d.data)).catch(()=>{})
      }
    })

    return {
      classicEditorConfig,
      ClassicEditor,
      currentUserData,
      display,
      isDesktop,
      nSettings,
      saveSettings,
    }

  }
}
</script>
