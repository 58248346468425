import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";
import i18n from "@/libs/i18n"
import storeHelper from '@/store/helper'

export default (store) => {

    const{endPointByRole, userRole} = storeHelper()
    const isDesktop = isPlatform('desktop')
    const updateProfile = async (data) => {

        const loader = await loadingController.create({message: i18n.global.t('Please wait...')})
        await loader.present()

        const alertB = await alertController.create({
            header: i18n.global.t('Error'),
            cssClass: isDesktop ? 'alert-web' : '',
            buttons: [{text: i18n.global.t('OK'), cssClass: isDesktop ? 'alert-btn-light' : ''}]
        });
        const toast  = await toastController.create({duration:3000})

        let tmp = {
            'is-advisor': 'update_profile',
            'is-admin': 'account/update_profile',
            'is-company': 'account/update_profile',
        }
        axios.post(endPointByRole+'/'+tmp[userRole.value], data)
            .then(resp => {
                if(!resp.data.success){
                    alertB.message = resp.data.message
                    alertB.present()
                }
                else{
                    if(userRole.value === 'is-advisor'){
                        store.commit('commit_advisor_data', data)
                    }
                    else if(userRole.value === 'is-admin'){
                        store.commit('commit_admin_data', data)
                    }
                    else{
                        store.commit('commit_user_data', data)
                    }
                    toast.message = i18n.global.t('thank_you_messages.profile_updated')
                    toast.present()
                }
            })
            .catch( error => {
                alertB.message = i18n.global.t('Error')+': '+error.response.status+' '+error.response.statusText
                alertB.present()
            })
            .then(()=> loader.dismiss())
    }

    return {
        updateProfile,
    }
}